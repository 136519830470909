import * as React from "react"
import ResponsiveImage, { ResponsiveImageSource } from "../responsive-image"
import logo from "./logo.png"

interface LogoProps {
  width?: string
}

export default function Logo({ width }: LogoProps) {
  return (
    <ResponsiveImage style={{ width }} alt={"Eddy and Wolff Logo"} src={logo}>
      <ResponsiveImageSource
        breakpoints={{
          "0px": "1000px",
        }}
        imageSizes={{
          "1000w": logo,
        }}
      />
    </ResponsiveImage>
  )
}
